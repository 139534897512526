<template>
  <section
    class="contact-users-list-wrapper"
    v-loading="loading"
    :element-loading-text="loadingText"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
  <div v-if="allUsers && allUsers.length === 0" style="justify-content: center; align-items: center; text-align: center; font-size: 20px; font-weight: regular; color: black;">
    <img src="@/assets/img/NoUsersFound.gif" style="width: 120px; height: 120px;margin-top:30%;" alt="No Users Found">
    <p>Only the sender is in this workflow. Click next to complete the workflow process.</p>
  </div>
    <div
      v-loading="loading"
      :element-loading-text="loadingText"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      v-else-if="allUsers && this.allUsers.length"
      class="contact-users-list"
    >
      <el-scrollbar wrap-style="max-height: calc(100vh - 150px);">
        <div
          class="el-row-wrapper"
          v-for="(user, index) in allUsers"
          :key="index"
          :id="`user_${index}`"
        >
          <el-form>
            <el-row :gutter="10" class="m-3">
              <el-col :xl="1" :lg="1" :md="1" :sm="2" :xs="12">
                <el-tooltip
                  effect="dark"
                  content="Is primary user?"
                  placement="top-start"
                >
                  <el-checkbox
                    v-model="user.is_primary_user"
                    style="margin-top: 40px; margin-left: -5px"
                    border
                    class="mr-1"
                    @change="checkPrimaryUser(index)"
                  ></el-checkbox>
                </el-tooltip>
              </el-col>
              <el-col :xl="3" :lg="3" :md="3" :sm="6" :xs="12">
                <el-form-item label="Contact Type" prop="contact_type">
                  <a
                    v-if="user.type != 'SENDER' && !user"
                    style="border: 0; float: right; cursor: pointer"
                    @click="editContact(index)"
                  >
                    <el-tooltip
                      effect="dark"
                      content="Edit contact"
                      placement="top-start"
                    >
                      <i
                        class="el-icon-edit-outline"
                        style="
                          font-weight: 800;
                          color: #f754a2;
                          font-family: inter;
                        "
                      ></i>
                    </el-tooltip>
                  </a>
                  <el-input
                    v-model="user.contact_type_name"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xl="4" :lg="4" :md="4" :sm="8" :xs="12">
                <el-form-item label="Email" prop="email">
                  <el-select
                    autocomplete="on"
                    class="w-100"
                    v-model="user.email"
                    placeholder="Search with Email"
                    suffix-icon="el-icon-search"
                    clearable
                    filterable
                    :allow-create="true"
                    default-first-option
                    @blur="onEmailLostFocus($event, index, user.email)"
                    @change="fillrecipientEmailData(index)"
                    :filter-method="handleEmailFilter"
                    @focus="selectFocus($event, index)"
                  >
                    <el-option
                      v-for="(contact, index) in getUserTypeContacts(
                        user.contact_type
                      )"
                      :key="index"
                      :value="contact.email"
                      :disabled="
                        fetchCurrentDocumentUsers(
                          contact.email,
                          user.configurable_document_id
                        )
                      "
                    >
                      {{ contact.email }}
                    </el-option>
                  </el-select>
                  <p
                    style="font-size: 12px"
                    class="error"
                    v-if="
                      user && (!user.email || !user.email.length) && is_submit
                    "
                  >
                    Email is Required
                  </p>
                </el-form-item>
              </el-col>
              <el-col :xl="4" :lg="4" :md="4" :sm="8" :xs="12">
                <el-form-item label="First Name" prop="first_name">
                  <el-input
                    autocomplete="off"
                    v-model="user.first_name"
                    placeholder="First Name"
                  ></el-input>
                  <p
                    style="font-size: 12px"
                    class="error"
                    v-if="
                      user &&
                      (!user.first_name ||
                        !user.first_name.length ||
                        (user.first_name.length &&
                          user.first_name.length < 1)) &&
                      is_submit
                    "
                  >
                    first name is Required
                  </p>
                </el-form-item>
              </el-col>
              <el-col :xl="4" :lg="4" :md="4" :sm="8" :xs="12">
                <el-form-item label="Last Name" prop="last_name">
                  <el-input
                    autocomplete="off"
                    v-model="user.last_name"
                    placeholder="Last Name"
                  ></el-input>
                  <p
                    style="font-size: 12px"
                    class="error"
                    v-if="
                      user &&
                      (!user.last_name || !user.last_name.length) &&
                      is_submit
                    "
                  >
                    last name is Required
                  </p>
                </el-form-item>
              </el-col>
              <el-col :xl="4" :lg="4" :md="4" :sm="8" :xs="12">
                <el-form-item label="Role" prop="role">
                  <el-input
                    autocomplete="off"
                    v-model="user.name"
                    placeholder="Role"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xl="4" :lg="4" :md="4" :sm="8" :xs="12">
                <el-form-item label="Signer type" prop="signer_type">
                  <el-select
                    v-model="user.user_type"
                    placeholder="Select Signer Type"
                    disabled
                  >
                    <el-option
                      v-for="(type, index) in ['SIGNER', 'CC', 'APPROVER']"
                      :key="index"
                      :value="type"
                      :label="type"
                      :disabled="type == 'CC'"
                    >
                      {{ type }}
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-scrollbar>
      <div>
        <!-- <el-button style="float: right" class="m-1" @click="mapTemplateUsers"
          >Save & Send</el-button
        > -->
        <el-checkbox
          v-model="enforceWorkflowOrderValue"
          style="margin-left: 50px"
          class="mt-1"
        >
          Enforce Workflow Order
        </el-checkbox>
      </div>
    </div>
    <div style="text-align: center" v-else>
      <h1 v-if="!allUsers && !this.allUsers.length">There is no receivers</h1>
    </div>
    <el-dialog
      :visible.sync="showErrors"
      width="100%"
      class="dialog-success outersize-confirm-type-three"
      title="Alert"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="checkToGoToTemplates"
    >
      <div style="text-align: center">
        These templates users are not configured well. May be contact type is
        missing:<br />
        <b v-if="errorTemplates.length">{{ errorTemplates.join(",") }}</b>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="gotoTemplates"
          >Go to templates</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="showAddContact"
      :before-close="checkContactTypes"
      title="Add Contact"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div>
        <AddContact
          v-on:close="closeAddContact"
          :contactData="newUser"
          :selectedContact="selectedContact"
          :contactTypes="contactTypes"
          :checkIsDisabled="checkIsDisabled"
          v-if="showAddContact"
        >
        </AddContact>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
// import AddContact from "@/components/contacts/AddContact";
import DocumentsHelper from "@/mixins/CompanyDocumentsHelper";
export default {
  name: "workflows-workflowConfigUsers",
  props: ["is_submit", "workflowData"],
  mixins: [DocumentsHelper],
  components: {
    AddContact: () =>
      import(
        "@/components/companyDocuments/configureDocuments/ConfigureContactDetails.vue"
      ),
  },
  async mounted() {
    this.loading = true;
    this.loadingText = "Fetching users...";
    if (this.$route.params.workflow_data_id) {
      this.workflow_data_id = this.$route.params.workflow_data_id;
      await Promise.all([
        this.$store.dispatch("contactTypes/fetchAllContactTypes", {
          get_all: true,type:'CONTACT_TYPE'
        }),
        this.$store.dispatch("contacts/fetchAllContacts", {
          get_all: true,type:'CONTACT'
        }),
        this.fetchWorkflowDataById(),
      ]);
    } else {
      this.loading = false;
    }
    // this.loading = false;
    console.log("================",this.workflowData)
  },
  computed: {
    ...mapGetters("templateWorkflow", [
      "getWorkflowCreatedData",
      "getWorkflowDataById",
      "getSelectedWorkflowUsers",
      "getCreatedworkflowStatus",
      "getCreatedworkflowDataById",
    ]),
    ...mapGetters("contactTypes", [
      "getAllContactTypes",
      "getContactTypeCreateStatus",
      "getNewContactTypeData",
    ]),
    ...mapGetters("contacts", ["getAllContacts", "getContactTypeCreateStatus"]),
    ...mapGetters("auth", ["getAuthenticatedUser"]),
  },
  data() {
    return {
      selectedUserIndex: -1,
      filterVal: [],
      workflow_data_id: null,
      selectedTemplates: [],
      allUsers: [],
      duplicateUsers: [],
      allUsersData: [],
      singleListUsers: [],
      filteredUsers: [],
      enforceWorkflowOrderValue: false,
      loading: false,
      initialLoad: false,
      showErrors: false,
      loadingText: " ",
      errorTemplates: [],
      primaryUser: null,
      is_new_render: true,
      alertShown: false,
      newUser: {
        email: "",
        first_name: "",
        e_signature_required: "",
        has_approval_access: false,
        last_name: "",
        phone: "",
        address: "",
        company_name: "",
        is_cc: false,
        name: "",
        user_type: "SIGNER",
        contact_type: "",
        contact: "",
        title: "",
      },
      showNewUserForm: false,
      callWatch: false,
      showAddContact: false,
      selectedContact: null,
      contactTypes: [],
      checkIsDisabled: true,
      workflowUserNameMapObj: {}
    };
  },
  watch: {
    allUsers() {
      this.mapTemplateUsers();
    },
    deep: true,
    enforceWorkflowOrderValue() {
      this.mapTemplateUsers();
    },
    is_submit() {
      this.mapTemplateUsers();
    },
  },
  methods: {
    editContact(index) {
      this.selectedContact = null;
      let id = this.allUsers[index].contact;
      if (!id) {
        let contact = this.getAllContacts.data.find(
          (e) => e.email == this.allUsers[index].email
        );
        id = contact._id;
      }
      this.selectedContact = id;
      this.selectedUserIndex = -1;
      this.selectedUserIndex = index;
      this.showAddContact = true;
    },
    async closeAddContact({ createContact, data }) {
      console.log("=-=",data)
      if (!createContact) {
        this.allUsers[this.selectedUserIndex].email = "";
        this.allUsers[this.selectedUserIndex].first_name = "";
        this.allUsers[this.selectedUserIndex].last_name = "";
      } else {
        await this.$store.dispatch("contacts/fetchAllContacts", {
          get_all: true,type:'CONTACT'
        });
        let savedContact = this.getAllContacts.data.find(
          (e) => e.email.toLowerCase() == data.email.toLowerCase()
        );
        let contactTypes = this.getContactTypesFromContact(savedContact);
        if (contactTypes && contactTypes.length) {
          let contactType = contactTypes.find(
            (e) =>
              e.contact_type ==
                this.allUsers[this.selectedUserIndex].contact_type ||
              (e.contact_type &&
                this.allUsers[this.selectedUserIndex].contact_type ==
                  e.contact_type._id)
          );
          if (contactType) {
            this.allUsers[this.selectedUserIndex] = {
              ...this.allUsers[this.selectedUserIndex],
              ...{
                ...{
                  temp_contact_type: contactType.name,
                  contact: data._id,
                  entity_data_id: contactType?.account_data_id,
                  first_name:data.first_name,
                  last_name:data.last_name
                },
              },
            };
            let tempusers = [];
            await setTimeout(() => {
              tempusers = this.allUsers;
              this.allUsers = [];
              this.allUsers = [...tempusers];
              this.loading = false;
            }, 1000);
            this.mapTemplateUsers();
          } else {
            if (contactType && contactType.name) {
              data.contact_type = contactType.name;
            }
            this.contactTypeAlertMethod(this.selectedUserIndex, data);
            return;
          }
        } else {
          this.$notify.error({
            title: "Error",
            message: "Contact types not selected!",
          });
          return;
        }
      }
      this.selectedUserIndex = -1;
      this.alertShown = false;
      this.showAddContact = false;
    },
    contactTypeAlertMethod(index, contact) {
      this.alertShown = true;
      this.$confirm(
        `This contact is not associated with ${this.allUsers[index].contact_type_name}. Please edit your contact.`,
        "Warning",
        {
          confirmButtonText: "Edit",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.selectedContact = contact._id;
          this.selectedUserIndex = -1;
          this.selectedUserIndex = index;
          this.newUser.email = this.allUsers[index].email;
          this.newUser.first_name = contact.first_name;
          this.newUser.last_name = contact.last_name;
          this.newUser.title = contact.title;
          this.newUser.middle_name = contact.middle_name;
          this.newUser.address = contact.address;
          this.newUser.company_name = contact.company_name;
          this.newUser.phone = contact.phone_number;
          this.newUser.contact_type = this.allUsers[index].contact_type;
          this.newUser.account_data_id = this.allUsers[index].entity_data_id;
          this.contactTypes = [...this.getContactTypesFromContact(contact)];
          this.showAddContact = true;
        })
        .catch(() => {
          this.alertShown = false;
          this.allUsers[index].email = "";
          this.allUsers[index].first_name = "";
          this.allUsers[index].last_name = "";
          this.allUsers[index].name = "";
        });
    },
    async checkContactTypes() {
      if (!this.allUsers[this.selectedUserIndex].contact) {
        this.loading = true;
        this.allUsers[this.selectedUserIndex].email = "";
        this.allUsers[this.selectedUserIndex].first_name = "";
        this.allUsers[this.selectedUserIndex].last_name = "";
        this.allUsers[this.selectedUserIndex] = {
          ...this.allUsers[this.selectedUserIndex],
        };
        let tempusers = [];
        await setTimeout(() => {
          tempusers = this.allUsers;
          this.allUsers = [];
          this.allUsers = [...tempusers];
          this.loading = false;
        }, 1000);
      } else {
        let contact = this.getAllContacts.data.find(
          (e) => e._id == this.allUsers[this.selectedUserIndex].contact
        );
        if (contact && contact.email) {
          this.allUsers[this.selectedUserIndex].email = contact.email;
        }
      }
      this.selectedUserIndex = -1;
      this.alertShown = false;
      this.showAddContact = false;
    },
    selectFocus(e, index) {
      this.selectedUserIndex = index;
    },
    handleEmailFilter(filterVal) {
      this.allUsers[this.selectedUserIndex].email = filterVal;
      if (filterVal) {
        this.userTypeContactsFiltered = this.getUserTypeContacts(null).filter(
          (contact) => contact.email.includes(filterVal)
        );
      } else {
        this.userTypeContactsFiltered = this.getUserTypeContacts(null);
      }
    },
    onEmailLostFocus(e, index, email) {
      let emailRe =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailRe.test(email)) {
        this.fillrecipientEmailData(index, true);
      }
    },
    async fetchWorkflowId() {
      this.loading = true;
      this.loadingText = "Fetching users...";
      let id = this.$route.params.workflow_id;
      await this.$store.dispatch("templateWorkflow/fetchWorkflowById", id);
      if (this.getWorkflowDataById) {
        console.log("this.getWorkflowDataById",this.getWorkflowDataById)
        this.selectedTemplates = this.getWorkflowDataById.templates_used;
        this.fetchWorkflowUsers();
      } else {
        this.loading = false;
      }
    },
    async fetchWorkflowDataById() {
      try {
        this.loading = true;
        let id = this.workflow_data_id;
        await this.$store.dispatch(
          "templateWorkflow/fetchWorkflowDataById",
          id
        );
        if (this.getCreatedworkflowStatus) {
          this.selectedTemplates =
            this.getCreatedworkflowDataById.templates_used;
          this.existUsers =
            this.getCreatedworkflowDataById &&
            this.getCreatedworkflowDataById.workflow_users
              ? this.getCreatedworkflowDataById.workflow_users
              : [];
          this.fetchWorkflowUsers();
        }
      } catch (err) {
        console.log(err);
      }
    },
    checkPrimaryUser(index) {
      this.allUsers.forEach((e, i) => {
        if (i != index && this.allUsers[index]["is_primary_user"] == true) {
          e.is_primary_user = false;
        }
        if (this.allUsers[index]["is_primary_user"] == false && i == index) {
          e.is_primary_user = true;
        }
      });
    },
    gotoTemplates() {
      this.$router.push({
        path: "/templates",
      });
    },
    checkToGoToTemplates() {
      this.showErrors = false;
    },
    fetchCurrentDocumentUsers(email) {
      this.loadingText = "Fetching template users...";
      // let ext = this.allUsers.find(
      //   (e) => e.configurable_document_id == id && e.email == email
      // );
      let constant_user = this.singleListUsers.find(
        (e) => e.email == email && e.constant_user
      );
      if (email == this.getAuthenticatedUser.email || constant_user) {
        return true;
      }
      return false;
    },
    async mapTemplateUsers() {
      let singleListUsers = [];
      await this.singleListUsers.forEach((user) => {
        this.allUsers.forEach((el) => {
          if (
            user.name == el.name &&
            user.contact_type == el.contact_type &&
            user.type != "SENDER" &&
            !user.constant_user
          ) {
            let userObj = { ...el };
            userObj.configurable_document_id = user.configurable_document_id;
            userObj.e_signature_order = user.e_signature_order;
            userObj.fields_required = user.fields_required;
            userObj.has_approval_access = user.has_approval_access;
            userObj.user_type = user.user_type;
            userObj.value = user.value;
            singleListUsers.push(userObj);
          }
        });
      });
      await this.allUsersData.map((list) => {
        let users = [];
        singleListUsers.forEach((user) => {
          if (list._id == user.configurable_document_id) {
            users.push(user);
          }
        });
        let sender = list.document_users.find((e) => e.type == "SENDER");
        let constatUsers = list.document_users.filter(
          (e) => e.type != "SENDER" && e.constant_user
        );
        if (constatUsers && constatUsers.length) {
          constatUsers.forEach((e) => users.push(e));
        }
        sender.email = this.getAuthenticatedUser.email;
        sender.first_name = this.getAuthenticatedUser.first_name;
        sender.last_name = this.getAuthenticatedUser.last_name;
        sender.phone = this.getAuthenticatedUser.phone
          ? this.getAuthenticatedUser.phone
          : "";
        sender.title = this.getAuthenticatedUser.title
          ? this.getAuthenticatedUser.title
          : "";
        users.push(sender);
        users = users.sort(
          (a, b) =>
            parseInt(a.e_signature_order) - parseInt(b.e_signature_order)
        );
        list.document_users = users;
        users = [];
      });
      this.$emit("createWorkflowData", {
        allUser: this.allUsersData,
        order: this.enforceWorkflowOrderValue,
        contactUsers: this.allUsers,
      });
      let tempusers = [];
      await setTimeout(() => {
        tempusers = this.allUsers;
        this.allUsers = [];
        this.allUsers = [...tempusers];
      }, 1000);
    },
    checkIfElementsAreSame(arr1, arr2) {
        for (const element1 of arr1) {
            for (const element2 of arr2) {
                if (element1 === element2) {
                    return true;
                }
            }
        }
        return false;
    },
    checkDuplicateEmails(users) {
      const emailMap = new Map();
        for (const user of users) {
            const { configurable_document_id, contact_type_name, contact_type, email } = user;
            const key = `${configurable_document_id}_${contact_type_name}_${contact_type}`;
            if (email) {
                if (emailMap.has(key)) {
                    if (emailMap.get(key) === email) {
                        alert("Same template should not have the same email ids.");
                        user.email = "";
                    }
                } else {
                    emailMap.set(key, email);
                }
            }
        }
    },
    async fillrecipientEmailData(index, isFromBlur = false) {
      this.callWatch = true;
      let isAlreadyTakenEmail = false;
      let templateIds = this.workflowUserNameMapObj[this.allUsers[index].name];
      let currentUserTemplateusersExists = false;
      this.allUsers.forEach((e, i) => {
        if(i !== index && this.allUsers[index].email == e.email){
          let ids = this.workflowUserNameMapObj[e.name];
          if(this.checkIfElementsAreSame(ids, templateIds)){
            currentUserTemplateusersExists = true;
          }
        }
      });
      if(currentUserTemplateusersExists){
        this.$message({
          message: "Same template should not have the same email ids.!",
          type: "error",
        });
        this.allUsers[index].email = "";
      }
      // this.allUsers.forEach((user, i) => {
      //   if (
      //     i != index &&
      //     user.email &&
      //     user.configurable_document_id == this.allUsers[index].configurable_document_id &&
      //     user.contact_type_name != this.allUsers[index].contact_type_name &&
      //     user.email == this.allUsers[index].email
      //   ) {
      //     isAlreadyTakenEmail = true;
      //   }
      // });
      if (
        this.allUsers[index] &&
        this.getAuthenticatedUser &&
        this.allUsers[index].email &&
        this.getAuthenticatedUser.email &&
        this.allUsers[index].email.toLowerCase() ==
          this.getAuthenticatedUser.email.toLowerCase()
      ) {
        this.$message({
          message: "You Cannot Add Sender Email!.",
          type: "warning",
        });
        this.allUsers[index].email = "";
      } 
      else if (isAlreadyTakenEmail) {
        this.$message({
          message: "Same template should not have the same email ids.!",
          type: "error",
        });
        this.allUsers[index].email = "";
      }
      else {
        var curEmail =
          this.allUsers[index] && this.allUsers[index].email
            ? this.allUsers[index].email.toLowerCase()
            : "";
        if (isFromBlur && curEmail.trim().length < 1) {
          curEmail = this.filterVal;
        }

        let contact = this.getAllContacts.data.filter(
          (contact) => contact.email && contact.email.toLowerCase() == curEmail
        );
        if (contact && contact.length) {
          let contactTypes = this.getContactTypesFromContact(contact[0]);
          if (contactTypes && contactTypes.length) {
            let id =
              this.allUsers[index].contact_type &&
              this.allUsers[index].contact_type._id
                ? this.allUsers[index].contact_type._id
                : this.allUsers[index].contact_type;
            let contactType = (contactTypes || []).find(
              (el) =>
                (el?.contact_type?._id && el.contact_type._id == id) ||
                el.contact_type == id
            );
            if (contactType) {
              this.allUsers[index].email = curEmail;
              if (!this.allUsers[index].first_name) {
                this.$set(
                  this.allUsers[index],
                  "first_name",
                  contact[0].first_name
                );
              } else {
                this.allUsers[index].first_name = contact[0].first_name;
              }
              if (!this.allUsers[index].last_name) {
                this.$set(
                  this.allUsers[index],
                  "last_name",
                  contact[0].last_name
                );
              } else {
                this.allUsers[index].last_name = contact[0].last_name;
              }
              // this.$set(user, "contact_type_name", typeData.name);
              this.allUsers[index].contact = contact[0]._id;
              this.allUsers[index].address =
                contact[0] && contact[0].address ? contact[0].address : "";
              this.allUsers[index].company_name =
                contact[0] && contact[0].company_name
                  ? contact[0].company_name
                  : "";
              this.allUsers[index].phone =
                contact[0] && contact[0].phone_number
                  ? contact[0].phone_number
                  : "";
              this.allUsers[index].title =
                contact[0] && contact[0].title ? contact[0].title : "";
              if (contactType?.account_data_id) {
                this.$set(
                  this.allUsers[index],
                  "entity_data_id",
                  contactType.account_data_id
                );
              }
            } else {
              if (
                this.allUsers[index].email &&
                this.allUsers[index].email.length &&
                !this.alertShown
              ) {
                this.contactTypeAlertMethod(index, contact[0]);
              }
            }
          } else {
            this.$notify.error({
              title: "Error",
              message: "Contact types not selected!",
            });
          }
        } else {
          if (
            !this.alertShown &&
            this.allUsers[index].email &&
            this.allUsers[index].email.length > 0
          ) {
            this.alertShown = true;
            this.$confirm(
              `This email is not in your contacts. Do you want to create a new contact?`,
              "Warning",
              {
                confirmButtonText: "Create",
                cancelButtonText: "Cancel",
                type: "warning",
              }
            )
              .then(() => {
                this.resetNewUserData();
                this.newUser.email = this.allUsers[index].email;
                this.newUser.first_name = this.allUsers[index].first_name;
                this.newUser.last_name = this.allUsers[index].last_name;
                this.newUser.contact_type = this.allUsers[index].contact_type;
                this.selectedUserIndex = -1;
                this.selectedUserIndex = index;
                this.selectedContact = null;
                this.showAddContact = true;
              })
              .catch(() => {
                this.alertShown = false;
                this.allUsers[index].email = "";
                this.allUsers[index].first_name = "";
                this.allUsers[index].last_name = "";
                this.setAllContacts(null);
              });
          }
        }

        this.mapTemplateUsers();
      }
    },
    setAllContacts() {
      this.userTypeContactsFiltered = this.getUserTypeContacts;
    },
    resetNewUserData() {
      this.newUser.email = "";
      this.newUser.first_name = "";
      this.newUser.last_name = "";
      this.newUser.phone = "";
      this.newUser.address = "";
      this.newUser.company_name = "";
      this.newUser.name = "";
      this.newUser.user_type = "SIGNER";
      this.newUser.contact_type = "";
      this.showNewUserForm = false;
    },
    getExistedUsers(users, name) {
      if (users && users.length) {
        let filteredUsers = users.filter(
          (e) => e.user_key && e.user_key.split("-")[0] == name
        );
        if (filteredUsers && filteredUsers.length) {
          return filteredUsers.length + 1;
        } else {
          return 1;
        }
      }
      return 1;
    },
    getUserTypeContacts(contact_type) {
      if (this.getAllContacts && this.getAllContacts.data) {
        if (contact_type) {
          let id = contact_type._id ? contact_type._id : contact_type;
          return this.getAllContacts.data.filter((e) => {
            if (e?.contact_types && e.contact_types.length) {
              let found = e.contact_types.findIndex(
                (el) =>
                  (el?.contact_type?._id && el.contact_type._id == id) ||
                  el.contact_type == id
              );
              return found == -1 ? false : true;
            } else {
              return e.contact_type && e.contact_type._id == id;
            }
          });
        } else {
          return this.getAllContacts.data;
        }
      }
      return [];
    },
    async fetchWorkflowUsers() {
      this.loading = true;
      this.loadingText = "Fetching template users...";
      let params = {
        templates_used: this.selectedTemplates.flatMap((e) => e.template_id),
      };
      await this.$store.dispatch(
        "templateWorkflow/fetchSelectedWorkflowUsers",
        params
      );
      if (this.getSelectedWorkflowUsers) {
        this.allUsersData = this.getSelectedWorkflowUsers;
        await this.getSelectedWorkflowUsers.forEach((list) => {
          let isError = false;
          list.document_users.forEach(async (user) => {
            if (user && user.type != "SENDER" && !user.constant_user) {
              if (user.contact_type) {
                if (
                  list._id == this.selectedTemplates[0]["template_id"]["_id"]
                ) {
                  if (!this.primaryUser) {
                    this.primaryUser = user;
                    this.$set(user, "is_primary_user", true);
                  }
                }
                let typeData = this.getAllContactTypes.find(
                  (type) => type._id == user.contact_type
                );
                if (!typeData) {
                  await this.$store.dispatch(
                    "contactTypes/fetchContactType",
                    user.contact_type
                  );
                  if (this.getNewContactTypeData) {
                    typeData = this.getNewContactTypeData;
                  }
                }
                let savedUser = this.existUsers.find((el) => {
                  if (
                    el &&
                    el.contact_type &&
                    user.contact_type &&
                    user.name &&
                    el.name &&
                    el.name == user.name &&
                    el.contact_type == user.contact_type
                  ) {
                    return el;
                  }
                });
                let existedUser = this.allUsers.find((el) => {
                  if (
                    el &&
                    el.contact_type &&
                    user.contact_type &&
                    user.name &&
                    el.name &&
                    el.name == user.name &&
                    el.contact_type == user.contact_type
                  ) {
                    return el;
                  }
                });
                if(this.workflowUserNameMapObj && this.workflowUserNameMapObj[user.name]){
                  this.workflowUserNameMapObj[user.name].push(user.configurable_document_id);
                }else{
                  this.workflowUserNameMapObj[user.name] = [user.configurable_document_id];
                }
                if (
                  user &&
                  !existedUser &&
                  typeData &&
                  typeData.name &&
                  !savedUser
                ) {
                  if (user.email == "") {
                    delete user.email;
                  }
                  this.$set(user, "contact_type_name", typeData.name);
                  this.allUsers.push(user);
                } else if (
                  user &&
                  !existedUser &&
                  typeData &&
                  typeData.name &&
                  savedUser
                ) {
                  if (user.email == "") {
                    delete user.email;
                  }
                  this.$set(savedUser, "contact_type_name", typeData.name);
                  this.allUsers.push(savedUser);
                } else {
                  this.duplicateUsers.push(user);
                }
              } else {
                isError = true;
              }
            }
            this.singleListUsers.push(user);
          });
          if (isError) {
            this.showErrors = true;
            this.errorTemplates.push(list.name);
          }
        });
        await this.allUsers.forEach((user) => {
          if (
            this.primaryUser &&
            this.primaryUser.name &&
            this.primaryUser.contact_type &&
            user.name == this.primaryUser.name &&
            user.contact_type == this.primaryUser.contact_type
          ) {
            this.$set(user, "is_primary_user", true);
          } else {
            this.$set(user, "is_primary_user", false);
          }
        });
        if (!this.is_new_render) {
          await this.mapTemplateUsers();
        }
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    removeStandardUsers() {
      this.filteredUsers.forEach((user) => {
        let existedUser = this.allUsers.find((el) => {
          if (
            el &&
            el.contact_type &&
            user.contact_type &&
            user.user_key &&
            el.user_key &&
            el.user_key == user.user_key &&
            el.contact_type == user.contact_type
          ) {
            return el;
          }
        });
        if (user && !existedUser) {
          this.allUsers.push(user);
        } else {
          this.duplicateUsers.push(user);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.el-row-wrapper {
  width: 100%;
  border: 1px solid #efefef;
  background: white;
}
</style>
<style lang="scss">
.workflow-editor-container {
  .contact-users-list-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    background: white;
    .contact-users-list {
      max-width: 1500px;
    }
  }
}
</style>
